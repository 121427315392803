import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Carousel from "./carousel"

const CarouselFondatori = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiFounder {
        nodes {
          Name
          Image { localFile { url } }
          Position
        }
      }
    }
  `)
  const founders = data.allStrapiFounder.nodes.sort((a, b) => a.Position - b.Position);

  return <div className="content-pad">
    <Carousel n={founders.length} height="210px">
      <div className="facce">
        {founders.map(f =>
          <div>
            <img src={f.Image.localFile.url}/>
            <b>{f.Name}</b>
          </div>
        )}
      </div>
    </Carousel>
  </div>
}

export default CarouselFondatori
