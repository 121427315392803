import React from "react"

import arrowPurple from "../images/arrow-down-purple.svg"
import arrowPink from "../images/arrow-down-pink.svg"
import asteriskPurple from "../images/asterisk-purple.svg"
import asteriskPink from "../images/asterisk-pink.svg"

class ReadMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false };
  }

  render = () => (
    <div>
      <p>
        {this.props.abstract}
        {this.state.showMore && this.props.children}
      </p>
      {this.state.showMore ?
        <a className="read-more" onClick={() => this.setState({ showMore: false })}>
          Leggi meno<br/>
          <img src={this.props.white ? asteriskPurple : asteriskPink}/>
        </a> :
        <a className="read-more" onClick={() => this.setState({ showMore: true })}>
          Leggi tutto<br/>
          <img src={this.props.white ? arrowPurple : arrowPink}/>
        </a>
      }
    </div>
  )
}

export default ReadMore
