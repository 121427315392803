import React from "react"

function computeK(windowWidth) {
  if (windowWidth > 1440) {
    return 3;
  }
  if (windowWidth > 1060) {
    return 2;
  }
  return 1;
}

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { pos: 0, k: 1 };
  }

  onWindowResize() {
    const n = this.props.n;
    const k = computeK(window.innerWidth);
    this.setState(prev => ({ k, pos: Math.min(prev.pos, n-k)}));
  }

  componentDidMount() {
    this.onWindowResize();
    window.addEventListener("resize", () => this.onWindowResize());
  }

  goLeft() {
    this.setState(prev => ({ pos: Math.max(prev.pos-1, 0) }))
  }

  goRight() {
    const n = this.props.n;
    const k = this.state.k;
    this.setState(prev => ({ pos: Math.min(prev.pos+1, n-k) }))
  }

  render() {
    const n = this.props.n; // total number of items
    const k = this.state.k; // number of visible items
    const pos = this.state.pos; // index of first visible item
    return (
      <div className="carousel" style={{height: this.props.height || '400px'}}>
        <a onClick={() => this.goLeft()}
          style={pos === 0 ? {opacity: 0.3} : {cursor: 'pointer'}}
        >◀</a>
        <div>
          <div className="carousel-window"
          style={{width: n/k*100+'%', left: -pos/k*100+'%'}}>
            {this.props.children}
          </div>
        </div>
        <a onClick={() => this.goRight()}
          style={pos === n-k ? {opacity: 0.3} : {cursor: 'pointer'}}
        >▶</a>
      </div>
    )
  }
}

export default Carousel
