import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Carousel from "./carousel"

const CarouselCommissione = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCommission {
        nodes {
          Name
          Qualification
          Sentence
          Image { localFile { url } }
          Position
        }
      }
    }
  `)
  const comms = data.allStrapiCommission.nodes.sort((a, b) => a.Position - b.Position);
  
  return <div className="content-pad">
    <Carousel n={comms.length} height="430px">
      <div className="facce">
        {comms.map(c =>
          <div>
            <img src={c.Image.localFile.url}/>
            <b>{c.Name}</b>
            <span>{c.Qualification}</span>
            <i>"{c.Sentence}"</i>
          </div>
        )}
      </div>
    </Carousel>
  </div>
}

export default CarouselCommissione
