import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import ReadMore from "../components/read-more"
import CarouselCommissione from "../components/carousel-commissione"
import CarouselFondatori from "../components/carousel-fondatori"
import NewsFlex from "../components/news-flex"
import Footer from "../components/footer"

/*import fondazione from "../images/fondazione.jpg"
import commissione from "../images/commissione.jpg"
import fondatori from "../images/fondatori.jpg"*/

const FondazionePage = ({ data }) => {
  data = data.strapiLaFondazione;

  return <div>
    <Header/>

    <div className="columns2 reverse white-bg">
      <div className="image-col">
        <img src={data.FondazioneImage.localFile.url}/>
      </div>
      <div className="text-col">
        <h2>la fondazione</h2>
        <p>{data.FondazioneText}</p>
      </div>
    </div>

    <div className="content-pad">
      <h2>il manifesto</h2>
      <p>{data.ManifestoText}</p>
      {/*<a className="read-more">Scarica lo statuto</a>
      <a className="read-more">Scarica il bilancio</a>*/}
    </div>

    <div className="columns2 white-bg">
      <div className="image-col">
        <img src={data.CommissioneImage.localFile.url}/>
      </div>
      <div className="text-col">
        <h2>la commissione scientifica</h2>
        <p>{data.CommissioneText}</p>
      </div>
    </div>

    <CarouselCommissione/>

    <div className="columns2 reverse white-bg">
      <div className="image-col">
        <img src={data.FondatoriImage.localFile.url}/>
      </div>
      <div className="text-col">
        <h2>i fondatori</h2>
        <ReadMore white={true} abstract={
          <span>{data.FondatoriText}<br/></span>
        }>
          {data.FondatoriMore}
        </ReadMore>
      </div>
    </div>

    <CarouselFondatori/>

    <NewsFlex white={true}/>

    <Footer/>
  </div>
}

export default FondazionePage

export const pageQuery = graphql`
  query {
    strapiLaFondazione {
      FondazioneText
      FondazioneImage { localFile { url } }
      ManifestoText
      CommissioneText
      CommissioneImage { localFile { url } }
      FondatoriText
      FondatoriMore
      FondatoriImage { localFile { url } }
    }
  }
`
